export default [
    {
        title: "Dashboard",
        icon: "HomeIcon",
        tag: "new",
        tagVariant: "light-warning",
        route: "dashboard-ecommerce",
        resource: "ACL",
        action: "read",
    },
];
