const resolveTag = () => {
    // const unreadSmsCount = store.getters["appAnalytics/unreadSmsCount"];
    const onHoldOrderCount = localStorage.getItem("onHoldOrderCount");
    
    if ( onHoldOrderCount > 0 ) {
        return {
            tag: onHoldOrderCount.toString(),
            tagVariant: "danger",
        }
    } else {
        return {
            tag: "0 On Hold",
            tagVariant: "info",
        };
    }
};

export default [
    {
        header: "Ecommerce",
        action: "read",
        resource: "ECOM",
    },
    {
        title: "Orders",
        icon: "ShoppingCartIcon",
        route: "ecommerce-order-list",
        action: "read",
        resource: "ECOM",
        tag: resolveTag().tag,
        tagVariant: resolveTag().tagVariant,
    },
    {
        title: "My Orders",
        icon: "ShoppingBagIcon",
        route: "ecommerce-my-orders-list",
        action: "read",
        resource: "ECOM",
    },
    {
        title: "Tickets",
        icon: "MailIcon",
        route: "ecommerce-tickets",
        action: "read",
        resource: "ECOM",
    },
    {
        title: "Customers",
        icon: "UserIcon",
        route: "ecommerce-customer-list",
        action: "read",
        resource: "ECOM",
    },
];
