// import store from "@/store";

const resolveTag = () => {
    // const unreadSmsCount = store.getters["appAnalytics/unreadSmsCount"];
    const unreadSmsCount = localStorage.getItem("unreadSmsCount");
    
    if ( unreadSmsCount > 0 ) {
        return {
            tag: unreadSmsCount.toString(),
            tagVariant: "danger",
        }
    } else {
        return {
            tag: "All Read",
            tagVariant: "info",
        };
    }
};

export default [
    {
        header: "Marketing",
        action: "read",
        resource: "YT",
    },
    {
        title: "Youtube Channels",
        icon: "YoutubeIcon",
        route: "marketing-channel-list",
        action: "read",
        resource: "YT",
    },
    {
        title: "Shortened Links",
        icon: "LinkIcon",
        route: "marketing-shortened-list",
        action: "read",
        resource: "YT",
    },
    {
        title: "SMS",
        icon: "MessageSquareIcon",
        route: "marketing-sms",
        action: "read",
        resource: "SMS",
        tag: resolveTag().tag, 
        tagVariant: resolveTag().tagVariant,
    },
];
