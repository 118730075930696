export default [
    {
        header: "Admin",
        action: "read",
        resource: "ADMIN",
    },
    {
        title: "Shops",
        icon: "GlobeIcon",
        route: "admin-shop-list",
        action: "read",
        resource: "ADMIN",
    },
    {
        title: "Users",
        icon: "UserIcon",
        route: "admin-user-list",
        action: "read",
        resource: "ADMIN",
    },
    {
        title: "Ticket Status",
        icon: "SlidersIcon",
        route: "admin-ticket-status",
        action: "read",
        resource: "ADMIN",
    },
    {
        title: "Order Source",
        icon: "TagIcon",
        route: "admin-order-source",
        action: "read",
        resource: "ADMIN",
    },
];
